.app-layout-logo {
  height: 64px;
  /*background: rgba(255, 255, 255, 0.2);*/
  margin: 16px;
  display: flex;
  justify-content: center;

  /*.logoImg {
    width: 160px;
    /*margin-right: 8px;*/
  /*}*/
}

.app-layout .app-layout-background {
  background: #fff;
}