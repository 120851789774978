div.transition-group {
  position: relative;
}

section.route-section {
  /*position: absolute;*/
  width: 100%;
  top: 0;
  left: 0;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.slide-enter {
    opacity: 0;
    transform: translateX(500px);
}
.slide-enter.slide-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 0.2s linear 0.4s;
}
.slide-exit {
    opacity: 1.0;
    transform: translateX(0);
}
.slide-exit.slide-exit-active {
    opacity: 0;
    position: absolute;
    width: 100%;
    transform: translateX(-500px);
    transition: all 0.2s linear;
}